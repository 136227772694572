const fetchUsers = [
    {
        id: 25,
        nombre: "Adeline Micco",
        apellido: "Topicware PVT LTD",
        edad: "50",
        username: "amiccoo",
        identificacion: "1538451",
        telefono: "(227) 598-1841",
        email: "amiccoo@whitehouse.gov",
        plan: "enterprise",
        estado: "pending",
        foto: "",
    },
    {
        id: 24,
        nombre: "Cleavland Hatherleigh",
        apellido: "Flipopia PVT LTD",
        edad: "50",
        username: "chatherleighn",
        identificacion: "8446332",
        telefono: "(700) 783-7498",
        email: "chatherleighn@washington.edu",
        plan: "team",
        estado: "pending",
        foto: "/img/2.748e7504.png",
    },
    {
        id: 23,
        nombre: "Kare Skitterel",
        apellido: "Ainyx PVT LTD",
        edad: "42",
        username: "kskitterelm",
        identificacion: "212315415",
        telefono: "(254) 845-4107",
        email: "kskitterelm@washingtonpost.com",
        plan: "basic",
        estado: "pending",
        foto: "/img/3.5df49333.png",
    },
    {
        id: 22,
        nombre: "Jameson Lyster",
        apellido: "Quaxo PVT LTD",
        edad: "63",
        username: "jlysterl",
        identificacion: "Ukraine",
        telefono: "(593) 624-0222",
        email: "jlysterl@guardian.co.uk",
        plan: "apellido",
        estado: "inactive",
        foto: "/img/8.4ac55be3.png",
    },
    {
        id: 21,
        nombre: "Selina Kyle",
        apellido: "Wayne Enterprises",
        edad: "50",
        username: "catwomen1940",
        identificacion: "USA",
        telefono: "(829) 537-0057",
        email: "irena.dubrovna@wayne.com",
        plan: "team",
        estado: "active",
        foto: "/img/1.9cba4a79.png",
    },
    {
        id: 20,
        nombre: "Wesley Burland",
        apellido: "Bubblemix PVT LTD",
        edad: "63",
        username: "wburlandj",
        identificacion: "Honduras",
        telefono: "(569) 683-1292",
        email: "wburlandj@uiuc.edu",
        plan: "team",
        estado: "inactive",
        foto: "/img/6.5e6ef87d.png",
    },
    {
        id: 19,
        nombre: "Travus Bruntjen",
        apellido: "Cogidoo PVT LTD",
        edad: "50",
        username: "tbruntjeni",
        identificacion: "1538451",
        telefono: "(524) 586-6057",
        email: "tbruntjeni@sitemeter.com",
        plan: "enterprise",
        estado: "active",
        foto: "",
    },
    {
        id: 18,
        nombre: "Bertha Biner",
        apellido: "Twinte PVT LTD",
        edad: "63",
        username: "bbinerh",
        identificacion: "Yemen",
        telefono: "(901) 916-9287",
        email: "bbinerh@mozilla.com",
        plan: "team",
        estado: "active",
        foto: "/img/7.8446f5dc.png",
    },
    {
        id: 17,
        nombre: "Brockie Myles",
        apellido: "Wikivu PVT LTD",
        edad: "42",
        username: "bmylesg",
        identificacion: "212315415",
        telefono: "(553) 225-9905",
        email: "bmylesg@amazon.com",
        plan: "basic",
        estado: "active",
        foto: "",
    },
    {
        id: 16,
        nombre: "Bentlee Emblin",
        apellido: "Yambee PVT LTD",
        edad: "23",
        username: "bemblinf",
        identificacion: "Spain",
        telefono: "(590) 606-1056",
        email: "bemblinf@wired.com",
        plan: "apellido",
        estado: "active",
        foto: "/img/6.5e6ef87d.png",
    },
    {
        id: 15,
        nombre: "Zsazsa McCleverty",
        apellido: "Kaymbo PVT LTD",
        edad: "42",
        username: "zmcclevertye",
        identificacion: "1538451",
        telefono: "(317) 409-6565",
        email: "zmcclevertye@soundcloud.com",
        plan: "enterprise",
        estado: "active",
        foto: "/img/2.748e7504.png",
    },
    {
        id: 14,
        nombre: "Maximilianus Krause",
        apellido: "Digitube PVT LTD",
        edad: "23",
        username: "mkraused",
        identificacion: "Democratic Republic of the Congo",
        telefono: "(167) 135-7392",
        email: "mkraused@stanford.edu",
        plan: "team",
        estado: "active",
        foto: "/img/9.3fd39137.png",
    },
    {
        id: 13,
        nombre: "Yoko Pottie",
        apellido: "Leenti PVT LTD",
        edad: "subscriber",
        username: "ypottiec",
        identificacion: "Philippines",
        telefono: "(907) 284-5083",
        email: "ypottiec@privacy.gov.au",
        plan: "basic",
        estado: "inactive",
        foto: "/img/7.8446f5dc.png",
    },
    {
        id: 12,
        nombre: "Seth Hallam",
        apellido: "Yakitri PVT LTD",
        edad: "subscriber",
        username: "shallamb",
        identificacion: "Peru",
        telefono: "(234) 464-0600",
        email: "shallamb@hugedomains.com",
        plan: "team",
        estado: "pending",
        foto: "/img/5.f13458cc.png",
    },
    {
        id: 11,
        nombre: "Jonah Wharlton",
        apellido: "Eare PVT LTD",
        edad: "subscriber",
        username: "jwharltona",
        identificacion: "United States",
        telefono: "(176) 532-6824",
        email: "jwharltona@oakley.com",
        plan: "team",
        estado: "inactive",
        foto: "/img/4.7e5368dc.png",
    },
    {
        id: 10,
        nombre: "Jillene Bellany",
        apellido: "Gigashots PVT LTD",
        edad: "42",
        username: "jbellany9",
        identificacion: "Jamaica",
        telefono: "(589) 284-6732",
        email: "jbellany9@kickstarter.com",
        plan: "apellido",
        estado: "inactive",
        foto: "/img/9.3fd39137.png",
    },
    {
        id: 9,
        nombre: "Franz Scotfurth",
        apellido: "Tekfly PVT LTD",
        edad: "subscriber",
        username: "fscotfurth8",
        identificacion: "China",
        telefono: "(978) 146-5443",
        email: "fscotfurth8@dailymotion.com",
        plan: "team",
        estado: "pending",
        foto: "/img/2.748e7504.png",
    },
    {
        id: 8,
        nombre: "Kathryne Liger",
        apellido: "Pixoboo PVT LTD",
        edad: "23",
        username: "kliger7",
        identificacion: "1538451",
        telefono: "(187) 440-0934",
        email: "kliger7@vinaora.com",
        plan: "enterprise",
        estado: "pending",
        foto: "/img/9.3fd39137.png",
    },
    {
        id: 7,
        nombre: "Breena Gallemore",
        apellido: "Jazzy PVT LTD",
        edad: "subscriber",
        username: "bgallemore6",
        identificacion: "Canada",
        telefono: "(825) 977-8152",
        email: "bgallemore6@boston.com",
        plan: "apellido",
        estado: "pending",
        foto: "",
    },
    {
        id: 6,
        nombre: "Silvain Halstead",
        apellido: "Jaxbean PVT LTD",
        edad: "23",
        username: "shalstead5",
        identificacion: "China",
        telefono: "(958) 973-3093",
        email: "shalstead5@shinystat.com",
        plan: "apellido",
        estado: "active",
        foto: "",
    },
    {
        id: 5,
        nombre: "Maggy Hurran",
        apellido: "Aimbo PVT LTD",
        edad: "subscriber",
        username: "mhurran4",
        identificacion: "Pakistan",
        telefono: "(669) 914-1078",
        email: "mhurran4@yahoo.co.jp",
        plan: "enterprise",
        estado: "pending",
        foto: "/img/10.d618a41d.png",
    },
    {
        id: 4,
        nombre: "Cyrill Risby",
        apellido: "Oozz PVT LTD",
        edad: "42",
        username: "crisby3",
        identificacion: "China",
        telefono: "(923) 690-6806",
        email: "crisby3@wordpress.com",
        plan: "team",
        estado: "inactive",
        foto: "/img/9.3fd39137.png",
    },
    {
        id: 3,
        nombre: "Marjory Sicely",
        apellido: "Oozz PVT LTD",
        edad: "42",
        username: "msicely2",
        identificacion: "Russia",
        telefono: "(321) 264-4599",
        email: "msicely2@who.int",
        plan: "enterprise",
        estado: "active",
        foto: "/img/1.9cba4a79.png",
    },
    {
        id: 2,
        nombre: "Halsey Redmore",
        apellido: "Skinder PVT LTD",
        edad: "23",
        username: "hredmore1",
        identificacion: "Albania",
        telefono: "(472) 607-9137",
        email: "hredmore1@imgur.com",
        plan: "team",
        estado: "pending",
        foto: "/img/10.d618a41d.png",
    },
    {
        id: 1,
        nombre: "Galen Slixby",
        apellido: "Yotz PVT LTD",
        edad: "63",
        username: "gslixby0",
        identificacion: "El Salvador",
        telefono: "(479) 232-9151",
        email: "gslixby0@abc.net.au",
        plan: "enterprise",
        estado: "inactive",
        foto: "",
    },
];

export default fetchUsers;
