<template>
    <div>
        <b-row class="justify-content-around px-1">
            <b-col
                class="mr-1 card round text-center animate__animated animate__backInLeft"
            >
                <span class="text-success font-weight-bolder glow border-bottom"
                    >200</span
                >
                <p class="mt-50">Pacientes activos</p>
            </b-col>
            <b-col
                class="mx-1 card round text-center animate__animated animate__backInLeft"
            >
                <span class="text-info font-weight-bolder glow border-bottom"
                    >200</span
                >
                <p class="mt-50">Pacientes en estado parcial</p>
            </b-col>
            <b-col
                class="mx-1 card round text-center animate__animated animate__backInRight"
            >
                <span class="text-warning font-weight-bolder glow border-bottom"
                    >200</span
                >
                <p class="mt-50">Pacientes sin plan</p>
            </b-col>
            <b-col
                class="ml-1 card round text-center animate__animated animate__backInRight"
            >
                <span class="text-danger font-weight-bolder glow border-bottom"
                    >200</span
                >
                <p class="mt-50">Pacientes inactivos</p>
            </b-col>
        </b-row>

        <div>
            <!-- <h3>Listado de pacientes</h3> -->
        </div>

        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row class="align-items-center">
                    <b-col cols="auto">
                        <span>Filtros</span>
                    </b-col>
                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>Plan</label>
                        <v-select
                            :options="planOptions"
                            class="w-100"
                            :reduce="(val) => val.value"
                        />
                    </b-col>
                    <b-col cols="12" md="4" class="mb-md-0 mb-2">
                        <label>Estado</label>
                        <v-select
                            :options="statusOptions"
                            class="w-100"
                            :reduce="(val) => val.value"
                        />
                    </b-col>
                    <b-col>
                        <b-button
                            variant="primary"
                            pill
                            class="animate__animated animate__zoomInUp"
                            @click="changeLoading"
                        >
                            <feather-icon icon="SearchIcon" />
                        </b-button>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>

        <!-- Table Container Card -->
        <b-card no-body class="mb-0 overflow-auto">
            <tabla-general
                :items="fetchUsers"
                :columns="tableColumns"
                searchPlaceholder="Buscar paciente"
                :is-busy="isLoading"
            >
                <template #cell(estado)="data">
                    <b-col>
                        <b-badge variant="success">Activo</b-badge>
                        <!-- <b-badge variant="info">Parcial</b-badge>
                        <b-badge variant="danger">Inactivo</b-badge> -->
                    </b-col>
                </template>
                <template #cell(opciones)="data">
                    <b-dropdown variant="link" no-caret>
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>
                        <b-dropdown-item @click="verPacientes(data.item.id)">
                            <feather-icon icon="FileTextIcon" />
                            <span class="align-middle ml-50">Ver paciente</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                            :to="{
                                name: '',
                            }"
                        >
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item>
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </tabla-general>
        </b-card>
    </div>
</template>

<script>
import { ref, onUnmounted } from "@vue/composition-api";
import fetchData from "./data.js";

export default {
    setup() {
        const isLoading = ref(true);
        const currentPage = ref(10);

        const planOptions = [
            { label: "Básico", value: "basico" },
            { label: "Eps", value: "eps" },
            { label: "Ips", value: "ips" },
        ];

        const statusOptions = [
            { label: "Pendiente", value: "pendiente" },
            { label: "Activo", value: "activo" },
            { label: "Inactivo", value: "inactivo" },
        ];

        const tableColumns = [
            { key: "id", label: "Id", sortable: false },
            { key: "identificacion", label: "Identificación", sortable: true },
            { key: "nombre", label: "Nombre", sortable: true },
            { key: "apellido", label: "Apellido", sortable: true },
            { key: "telefono", label: "Teléfono", sortable: true },
            { key: "edad", label: "Edad", sortable: true },
            { key: "plan", label: "Plan", sortable: true },
            {
                key: "estado",
                label: "Estado",
                sortable: true,
                class: "text-center",
            },
            { key: "opciones", label: "Opciones", sortable: false },
        ];

        const fetchUsers = fetchData;

        function changeLoading() {
            isLoading.value = !isLoading.value;
        }

        function verPacientes(idPaciente) {
            this.$router.push({
                name: "ver-mis-paciente",
                params: {
                    id_paciente: idPaciente,
                },
            });
        }

        return {
            planOptions,
            statusOptions,
            fetchUsers,
            tableColumns,
            currentPage,
            isLoading,
            changeLoading,
            verPacientes,
        };
    },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
.glow {
    font-size: 40px;
}
</style>
